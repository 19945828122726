import React from 'react';
import {ResultsModel} from '../model/results.model';


export const ResultsContext = React.createContext<ResultsModel>({
    userId: '',
    profession: '',
    onProfessionChange: (value: string) => {},
    agreeToTerms: false,
    onAgreeToTermsChange: (value: boolean) => {},
    licenseNumber: '',
    onLicenseNumberChange: (value: string) => {},
    dateOfBirth: null,
    onDateOfBirthChange: (value: Date | null) => {},
    pregnancy: false,
    onPregnancyChange: (value: boolean) => {},
    plannedPregnancy: false,
    onPlannedPregnancyClick: (value: boolean) => {},
    circulatorySystemDisease: false,
    onCirculatorySystemDisease: (value: boolean) => {},
    diabetes: false,
    onDiabetes: (value: boolean) => {},
    chronicRespiratoryDisease: false,
    onChronicRespiratoryDisease: (value: boolean) => {},
    chronicKidneyDisease: false,
    onChronicKidneyDisease: (value: boolean) => {},
    chronicLiverDisease: false,
    onChronicLiverDisease: (value: boolean) => {},
    viralHepatitisC: false,
    onViralHepatitisC: (value: boolean) => {},
    depression: false,
    onDepression: (value: boolean) => {},
    neoplasticDisease: false,
    onNeoplasticDisease: (value: boolean) => {},
    spleenDysfunction: false,
    onSpleenDysfunction: (value: boolean) => {},
    immuneDisorders: false,
    onImmuneDisorders: (value: boolean) => {},
    usedMedications: false,
    onUsedMedications: (value: boolean) => {},
    hivInfection: false,
    onHivInfection: (value: boolean) => {},
    boneMarrowTransplantation: false,
    onBoneMarrowTransplantation: (value: boolean) => {},
    boneMarrowTransplantationBefore: false,
    onBoneMarrowTransplantationBefore: (value: boolean) => {},
    boneMarrowTransplantationAfter: false,
    onBoneMarrowTransplantationAfter: (value: boolean) => {},
    organTransplantation: false,
    onOrganTransplantation: (value: boolean) => {},
    organTransplantationBefore: false,
    onOrganTransplantationBefore: (value: boolean) => {},
    organTransplantationAfter: false,
    onOrganTransplantationAfter: (value: boolean) => {},
    cochlearImplantation: false,
    onCochlearImplantation: (value: boolean) => {},
    cerebroSpinalFluidLeak: false,
    onCerebroSpinalFluidLeak: (value: boolean) => {},
    medicalStudentHealthcare: false,
    onMedicalStudentHealthcare:  (value: boolean) => {},
    neonatologyPediatricsWorker: false,
    onNeonatologyPediatricsWorker:  (value: boolean) => {},
    infectiousDiseasesGastroenterologyWorker: false,
    onInfectiousDiseasesGastroenterologyWorker:  (value: boolean) => {},
    policeman: false,
    onPoliceman:  (value: boolean) => {},
    fireFighter: false,
    onFireFighter:  (value: boolean) => {},
    borderGuard: false,
    onBorderGuard:  (value: boolean) => {},
    army: false,
    onArmy:  (value: boolean) => {},
    healthCareHomeResident: false,
    onHealthCareHomeResident:  (value: boolean) => {},
    otherMultiPersonPlace: false,
    onOtherMultiPersonPlace:  (value: boolean) => {},
    outdoorActivity: false,
    onOutdoorActivity:  (value: boolean) => {},
    hepatitisBExposure: false,
    onHepatitisBExposure:  (value: boolean) => {},
    cigarettesAlcoholAbuse: false,
    onCigarettesAlcoholAbuse:  (value: boolean) => {},
    travelingPlans: false,
    onTravelingPlans:  (value: boolean) => {}
});
